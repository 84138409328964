export const translations = {
  // MENU
  menu_about_cs: "O nás",
  menu_about_en: "About us",
  menu_about_de: "Wer wir sind",

  menu_projects_cs: "Projekty",
  menu_projects_en: "Projects",
  menu_projects_de: "Projekte",
  menu_projects_cs: "Projekty",
  menu_projects_en: "Projects",
  menu_projects_de: "Projekte",

  menu_team_cs: "Tým",
  menu_team_en: "Team",
  menu_team_de: "Team",
  menu_team_cs: "Tým",
  menu_team_en: "Team",
  menu_team_de: "Team",

  menu_contacts_cs: "Kontakty",
  menu_contacts_en: "Contacts",
  menu_contacts_de: "Kontakte",

  menu_jobs_cs: "Nabídka práce",
  menu_jobs_en: "Jobs",
  menu_jobs_de: "Jobs",

  // About us
  about_how_we_work_cs: "Jak pracujeme",
  about_how_we_work_en: "How we work",
  about_how_we_work_de: "Wie wir arbeiten",

  about_what_we_believe_cs: "Čemu věříme",
  about_what_we_believe_en: "What we believe",
  about_what_we_believe_de: "An was wir glauben",

  // Jumbotron
  see_our_work_cs: "Podívejte se na naši práci",
  see_our_work_en: "See our work",
  see_our_work_de: "Siehe unsere Arbeit",

  // Our projects
  our_projects_cs: "Naše projekty",
  our_projects_en: "Our projects",
  our_projects_de: "Unsere Projekte",
  // Our projects
  our_projects_cs: "Naše projekty",
  our_projects_en: "Our projects",
  our_projects_de: "Unsere Projekte",

  see_more_cs: "Více info",
  see_more_en: "See more",
  see_more_de: "Mehr sehen",
  see_more_cs: "Více info",
  see_more_en: "See more",
  see_more_de: "Mehr sehen",

  // Wrote about us
  link_title_cs: "Napsali o nás",
  link_title_en: "Wrote about us",
  link_title_de: "Habe über uns geschrieben",
  // Wrote about us
  link_title_cs: "Napsali o nás",
  link_title_en: "Wrote about us",
  link_title_de: "Habe über uns geschrieben",

  link_subtitle_cs: "Podívejte se, jaký ohlas naše práce měla v médiích.",
  link_subtitle_en: "Check out how media reacted to our work.",
  link_subtitle_de:
    "Sehen Sie sich an, wie die Medien auf unsere Arbeit reagiert haben.",
  link_subtitle_cs: "Podívejte se, jaký ohlas naše práce měla v médiích.",
  link_subtitle_en: "Check out how media reacted to our work.",
  link_subtitle_de:
    "Sehen Sie sich an, wie die Medien auf unsere Arbeit reagiert haben.",

  // Our team
  our_team_title_cs: "Náš tým",
  our_team_title_en: "Our team",
  our_team_title_de: "Unser Team",
  // Our team
  our_team_title_cs: "Náš tým",
  our_team_title_en: "Our team",
  our_team_title_de: "Unser Team",

  our_team_subtitle_cs:
    "Kdo stojí za našimi algoritmy, které zachraňují životy.",
  our_team_subtitle_en: "Who stands behind our algorithms that save lives.",
  our_team_subtitle_de:
    "Wer steht hinter unseren Algorithmen, die Leben retten.",
  our_team_subtitle_cs:
    "Kdo stojí za našimi algoritmy, které zachraňují životy.",
  our_team_subtitle_en: "Who stands behind our algorithms that save lives.",
  our_team_subtitle_de:
    "Wer steht hinter unseren Algorithmen, die Leben retten.",

  // FOOTER
  hiring_title_cs: "Chcete u nás pracovat?",
  hiring_title_en: "We are hiring!",
  hiring_title_de: "Wir stellen ein!",
  // FOOTER
  hiring_title_cs: "Chcete u nás pracovat?",
  hiring_title_en: "We are hiring!",
  hiring_title_de: "Wir stellen ein!",

  hiring_subtitle_cs: "Více informací",
  hiring_subtitle_en: "See details",
  hiring_subtitle_de: "Siehe mehr",
  hiring_subtitle_cs: "Více informací",
  hiring_subtitle_en: "See details",
  hiring_subtitle_de: "Siehe mehr",

  contact_us_cs: "Kontaktujte nás",
  contact_us_en: "Contact us",
  contact_us_de: "Kontaktieren Sie uns",
  contact_us_cs: "Kontaktujte nás",
  contact_us_en: "Contact us",
  contact_us_de: "Kontaktieren Sie uns",

  contact_us_email_cs: "e-mailem",
  contact_us_email_en: "by email",
  contact_us_email_de: "per E-Mail",
  contact_us_email_cs: "e-mailem",
  contact_us_email_en: "by email",
  contact_us_email_de: "per E-Mail",

  mail_cs: "Korespondenční adresa",
  mail_en: "Mailing address",
  mail_de: "Postanschrift",
  mail_cs: "Korespondenční adresa",
  mail_en: "Mailing address",
  mail_de: "Postanschrift",

  google_map_cs: "Ukázat na mapě",
  google_map_en: "Show on the map",
  google_map_de: "Auf der Karte anzeigen",
  google_map_cs: "Ukázat na mapě",
  google_map_en: "Show on the map",
  google_map_de: "Auf der Karte anzeigen",

  billing_cs: "Fakturační údaje",
  billing_en: "Billing information",
  billing_de: "Abrechnungsdaten",
  billing_cs: "Fakturační údaje",
  billing_en: "Billing information",
  billing_de: "Abrechnungsdaten",

  contact_person_cs: "Kontaktní osoba",
  contact_person_en: "Contact person",
  contact_person_de: "Gesprächspartner",
  contact_person_cs: "Kontaktní osoba",
  contact_person_en: "Contact person",
  contact_person_de: "Gesprächspartner",

  follow_us_cs: "Sledujte nás",
  follow_us_en: "Follow us",
  follow_us_de: "Folge uns",
  follow_us_cs: "Sledujte nás",
  follow_us_en: "Follow us",
  follow_us_de: "Folge uns",

  // Copyright
  copyright_cs: "Všechna práva vyhrazena",
  copyright_en: "All rights reserved",
  copyright_de: "Alle Rechte vorbehalten",
  // Copyright
  copyright_cs: "Všechna práva vyhrazena",
  copyright_en: "All rights reserved",
  copyright_de: "Alle Rechte vorbehalten",
};
