import { Company } from "./classes/Company";
import { Projects } from "./classes/Projects";
import { Links } from "./classes/Links";
import { Team } from "./classes/Team";
import { ContentService } from "./services/ContentService";
import { ScrollNavigation } from "./classes/ScrollNavigation";
import { translations } from "./constants/translations";

export const validLanguages = ["cs", "en", "de"];
const defaultLanguage = "cs";

export class App {
  languageButtons = document.querySelectorAll("[data-jsLanguage]");
  copyrightDateContainer = document.querySelector(
    '[data-jsSelector="copyright-date"]',
  );
  jobsNavItem = document.querySelector(".nav-jobs");
  contentService = new ContentService();

  company = new Company();
  projects = new Projects();
  links = new Links();
  team = new Team();
  scrollNavigation = new ScrollNavigation();

  async init() {
    // get rid of /#/ in the url
    history.replaceState(null, null, window.location.href.replace("#/", ""));
    const urlLanguage = window.location.pathname.split("/")[1]; // first is always empty string, then there's a language

    // Initialize custom scroll navigation
    this.scrollNavigation.init();

    const initialLanguage =
      urlLanguage && validLanguages.includes(urlLanguage)
        ? urlLanguage
        : validLanguages.includes(window.navigator.language)
          ? window.navigator.language
          : defaultLanguage;

    // Make initial language toggle active
    const languageButton = document.querySelector(
      `[data-jsLanguage="${initialLanguage}"]`,
    );
    if (languageButton) {
      languageButton.classList.add("active");
    }

    // Translate static labels
    this._renderStaticLabels(initialLanguage);

    // Render copyright date
    if (this.copyrightDateContainer) {
      this._renderCopyrightDate(initialLanguage);
    }

    // Load company
    const company = await this.contentService.getCompany();
    console.log("Loaded company", company);
    if (!company) {
      return;
    }

    // Render all info
    await this._render(company, initialLanguage);

    // Scroll to correct position after loading
    this.scrollNavigation.scrollToElementInHash();

    // Listen for language change
    this.languageButtons.forEach((button) =>
      button.addEventListener("click", (e) => {
        e.preventDefault();

        const language = button.dataset.jslanguage;
        if (validLanguages.includes(language)) {
          console.log("Switching to", language);

          // Add language to url
          window.history.pushState("", "", language);

          // Make toggle active
          this.languageButtons.forEach((b) => b.classList.remove("active"));
          button.classList.add("active");

          // Remove this if the Jobs button should be available for other languages
          if (this.jobsNavItem) {
            if (language === "cs") {
              this.jobsNavItem.style.display = "block";
            } else {
              this.jobsNavItem.style.display = "none";
            }
          }

          // Rerender fetched content
          this._render(company, language);

          // Translate static labels
          this._renderStaticLabels(language);
        } else {
          console.log("Not a valid language:", language);
        }
      }),
    );
  }

  async _render(company, language) {
    // Render company info
    this.company.init(company, language);

    // Render projects, media links and team
    const photosDirectory = `${this.contentService.apiUrl}/${company["people_dir"]}/photos`;
    const linksImagesDirectory = `${this.contentService.apiUrl}/${company["links_dir"]}/img`;
    this.contentService.sourcesFilename = company["sources_filename"];
    await this.contentService
      .getProjects(company["projects_dir"])
      .then((projects) => this.projects.init(projects, language));
    await this.contentService
      .getLinks(company["links_dir"])
      .then((links) => this.links.init(linksImagesDirectory, links, language));
    await this.contentService
      .getTeam(company["people_dir"])
      .then((members) =>
        this.team.init(photosDirectory, members, company["email"], language),
      );
  }

  _renderStaticLabels(language) {
    const translatableItems = document.querySelectorAll("[data-translate]");
    translatableItems.forEach((item) => {
      const translationKey = item.dataset.translate;
      item.innerHTML =
        translations[`${translationKey}_${language}`] ?? item.innerHTML;
    });
  }

  _renderCopyrightDate() {
    this.copyrightDateContainer.innerHTML = `${new Date().getFullYear()}`;
  }
}
